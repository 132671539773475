<template>
    <div class="container mt-2">
        <v-img
            class="content"
            max-width="80%"
            :src="require('@/assets/secrete.png')"
        ></v-img>
    </div>
</template>

<script>

export default {
    name: "SecretE"
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    max-width: 60%;
}

</style>